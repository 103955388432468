import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea } from './Input'

const Form = styled.form`
  max-width: 500px;
  flex-basis: 75%;
  flex-grow: 1;

  h2 {
    margin-bottom: 2rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }
`

const Fieldset = styled.div`
  /* margin: -0.375rem; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  /* @media (min-width: 940px) {
    margin: -0.5rem;
  } */
`

interface Props {
  children?: React.ReactChild
  title?: string
  showMessage?: boolean
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  static defaultProps = {
    showMessage: true,
  }

  state = {
    buttonMessage: `Let's chat`,
    email: '',
    message: '',
    name: '',
    phone: '',
    website: '',
  }

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value,
    })
  }

  encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    // @ts-ignore
    // fbq('track', 'Lead', {
    //   content_category: 'form',
    //   content_name: window.location.pathname
    // })

    this.setState({ buttonMessage: 'Working...' })

    fetch('/.netlify/functions/send-email', {
      body: JSON.stringify(this.state),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error()
        }
        this.setState({ buttonMessage: 'Thank You!' })
      })
      .catch(() => {
        this.setState({ buttonMessage: 'Error - Try later!' })
      })
  }

  render() {
    const { type, title, showMessage } = this.props
    const isAudit = type === 'audit'

    return (
      <Form name="contactForm" method="post" onSubmit={this.handleSubmit}>
        <h2>{title || 'Send us a message'}</h2>
        {/* {showMessage && (
          <p>
            If your enquiry relates to making an appointment we request you
            contact the office directly on 9473 8577 to find a suitable time for
            you
          </p>
        )} */}
        <Fieldset>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required={true}
            value={this.state.name}
            onChange={this.handleChange}
          />

          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone number"
            required={true}
            value={this.state.phone}
            onChange={this.handleChange}
          />

          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            required={true}
            value={this.state.email}
            onChange={this.handleChange}
          />

          <Textarea
            id="message"
            name="message"
            placeholder="Message"
            rows={showMessage ? 4 : 1}
            onChange={this.handleChange}
            value={this.state.message}
          />

          <Box my={3}>
            <Button
              small={true}
              full={true}
              inverted={true}
              type="submit"
              onSubmit={this.handleSubmit}
            >
              {this.state.buttonMessage}
            </Button>
          </Box>
        </Fieldset>
      </Form>
    )
  }
}
