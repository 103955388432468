import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Button from '../components/Button'
import { buttons } from 'polished'
import { Flex } from 'grid-styled'

const Container = styled.section`
  padding: 50px 5%;
  width: 100%;
  height: auto;
  float: left;
  transition: all 250ms ease-in-out;

  @media (max-width: 1000px) {
    padding: 40px 2%;
  }
  @media (min-width: 1500px) {
    padding: 40px 5%;
  }

  > div {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 720px) {
      flex-direction: column !important;
    }
  }

  &:nth-child(2n + 1) > div {
    flex-direction: row-reverse;
  }
`

const Image = styled(Img)`
  box-shadow: 0 52px 85px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
`

const ImageContainer = styled.figure`
  height: auto;
  width: 100%;

  .gatsby-image-outer-wrapper,
  ${Image} {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 720px) {
    max-width: 600px;
    width: 90%;
    margin: 0 auto;
  }
`

const Content = styled.article`
  width: 100%;
  height: auto;
  margin-left: 50px;
  h2 {
    font-size: 2.25em;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }
  p {
    font-size: 1em;
    color: #333;
    max-width: 75%;
    line-height: 1.5;
  }
  @media (max-width: 1200px) {
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 0.9em;
      max-width: 85%;
    }
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 720px) {
    max-width: 600px;
    width: 70%;
    margin: 30px auto 0;
    p {
      max-width: 100%;
    }
  }
  @media (max-width: 500px) {
    width: 90%;
    margin: 30px auto 0;
    p {
      max-width: 100%;
    }
  }
`

const ContentCard = ({ image, title, text, buttons, filled, to }) => (
  <Container>
    <div>
      <ImageContainer>
        <Link to={to}>
          <Image fluid={image.fluid} alt={title} />
        </Link>
      </ImageContainer>
      <Content>
        <h2>{title}</h2>
        <p>{text}</p>
        {buttons.map(({ to, text }) => (
          <Flex mr={2} mb={2} style={{ float: 'left' }}>
            <Link to={to}>
              <Button color="#FF002D" small={true} outline={true}>
                {text}
              </Button>
            </Link>
          </Flex>
        ))}
      </Content>
    </div>
  </Container>
)

export default ContentCard
