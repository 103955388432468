import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore
} from '../components/styles'
import styled from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'
import Card from '../components/Card'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'
import CTACard from '../components/CTACard'
import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'

const Logos = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 900px;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin: -50px auto 0;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    .gatsby-image-outer-wrapper:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }

  .gatsby-image-outer-wrapper {
    width: 80px;
    height: auto;
  }
`

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  section {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    figure {
      width: calc(100vw / 8);
    }
  }

  h2 {
    font-weight: 400;
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${props => props.theme.colors.primary};
    }
  }
`

const HomeHeader = styled.header`
  height: 80vh;
  padding-top: 7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    background-image: linear-gradient(-45deg, #fe9124 0%, #e71506 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: skewY(-8deg);
    transform-origin: left top 0px;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  h2 {
    color: #fff;
    font-weight: 500;
    max-width: 700px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 500;
    line-height: 150%;
    padding-right: 2rem;
    max-width: 500px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 9rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    /* padding-left: 10rem; */
  }
`

const ServicesContainer = styled(Container)`
  > div {
    margin-bottom: 6rem;
  }
`
const CardsContainer = styled(Container)`
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  background: #000;
  img {
    opacity: 0.3 !important;
  }
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const AboutContact = ({ data }) => (
  <React.Fragment>
    <StyledBackgroundSection
      image={data.drSellars.childImageSharp}
      title={'Custom Designed Sportswear'}
    >
      <p>
        GFIT Sportswear offers high- performance custom made sportswear and
        activewear for local sporting clubs, schools, state and national
        sporting bodies.
      </p>
      <p>
        No company can come close to the GFIT degree of personal customer
        service or the amount of customization that goes into every client. Our
        dedicated in-house design team will work with you every step of the way,
        they understand that every individual client’s needs are different and
        simply go about getting you the look that you and your team are looking
        to achieve.
      </p>
      <p>
        If you're interested in getting a custom design of your own for your
        club, association, business or school contact our design team today.
        GFIT brings your design concepts to life and in next to no time make it
        a reality. Give your team and your sponsors the identity that they
        deserve.
      </p>

      <p>
        Check out some of our testimonials and individual sports pages and
        contact the GFIT team today.
      </p>

      <Link to="/about">
        <Button outline={true} color="#fff" small={true}>
          Read more
        </Button>
      </Link>
    </StyledBackgroundSection>

    <Flex mt={5} flexDirection="column" justifyContent="center">
      <h2 style={{ textAlign: 'center', marginBottom: '1em', fontWeight: 400 }}>
        Why GFit Sportswear?
      </h2>

      <CardsContainer>
        <Cards>
          <Card
            color="primary"
            shadow={true}
            title="Skilled"
            content="With decades of industry experience, a team of vibrant switched on designers and a custom manufacturing centre the GFIT team will get you the desired outcome every time. See some of our testimonials and browse our individual sport pages to see some of our recent designs."
          />

          <Card
            color="tertiary"
            shadow={true}
            title="Trusted"
            content="We are trusted by some of Australia's leading schools, high profile teams and individual sports people, as well as hundreds of grass roots local clubs. We deliver on what we promise. Big and small alike, every one of our customers is important to us, we pride ourselves on customer retention, this is a reflection on the GFIT staff and their willingness to help in any way possible from start to finish."
          />

          <Card
            color="purple"
            shadow={true}
            title="Simple"
            content="Our goal is to make the process as simple as possible for you “the customer”. We will guide you through the process every step of the way, our job is not complete until your team is taking the field looking better than ever. People keep coming back to GFIT time after time as life isn’t meant to be difficult. "
          />
        </Cards>
      </CardsContainer>
    </Flex>

    <Contact />
  </React.Fragment>
)

/* prettier-ignore */
interface IProps {
  data: {
    womanWaterfall: {
      childImageSharp: {
        fluid: object
      }
    };
    woodsWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    waterBodyWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    faceCloseUp: {
      childImageSharp: {
        fluid: object
      }
    };
    drSellars: {
      childImageSharp: {
        fluid: object
      }
    };
    nonSurgical: {
      childImageSharp: {
        fluid: object
      }
    };
    asaps: {
      childImageSharp: {
        fluid: object
      }
    };
    asps: {
      childImageSharp: {
        fluid: object
      }
    };
    fracs: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  // if (!data) {
  //   console.log('Error: no data found')
  //   return null
  // }

  return (
    <Layout header="light">
      <Helmet>
        <title>GFit Sportswear - Custom Australian Sporting Apparel</title>
        <meta
          name="description"
          content="GFit designs and produces high quality sportswear for every major sport in Australia. Netball, AFL, Rugby, Cricket, Soccer, Athletics, and more. Contact us about a free custom design for your club today."
        />
        <meta
          name="google-site-verification"
          content="Ngjmc1Sk0AgniI0nPAd5pZQX8yvpaPUd5bTtc0gZc24"
        />
      </Helmet>

      <Hero
        home={true}
        noForm={true}
        bgImage={data.womanWaterfall.childImageSharp}
        height="600px"
      >
        <span>GFIT</span>
        <h1 style={{ marginTop: 0 }}>
          Trusted by, <br />
          Australia's <i>best</i>
        </h1>
        <Flex>
          <Link to="/sports/soccer">
            <Button color={'#fff'} small={true} outline={true}>
              SEE OUR SOCCER GEAR >
            </Button>
          </Link>
        </Flex>
      </Hero>

      <Logos>
        {data.logos.edges.map(
          image =>
            image.node.childImageSharp && (
              <Img fluid={image.node.childImageSharp.fluid} />
            )
        )}
      </Logos>

      <AlternatingContent>
        <ContentCard
          image={data.onField.childImageSharp}
          title="On Field"
          text="With testimonials from some of Australia's biggest sporting clubs, our high-quality on field apparel is designed to last. Exhibit your unique club look when it counts."
          buttons={[
            { text: 'AFL', to: '/sports/afl#on-field' },
            // { text: 'Athletics', to: '/sports/athletics#on-field' },
            { text: 'Touch', to: '/sports/touch-football#on-field' },
            { text: 'Basketball', to: '/sports/basketball#on-field' },
            { text: 'Cricket', to: '/sports/cricket#on-field' },
            { text: 'Hockey', to: '/sports/hockey#on-field' },
            { text: 'Netball', to: '/sports/netball#on-field' },
            { text: 'Rugby', to: '/sports/rugby#on-field' },
            { text: 'Soccer', to: '/sports/soccer#on-field' },
            { text: 'Schools', to: '/sports/schools#on-field' },
            { text: 'Corporate', to: '/sports/corporate#on-field' },
            { text: 'Hi-Vis', to: '/sports/hi-vis#on-field' }
          ]}
        />
        <ContentCard
          image={data.offField.childImageSharp}
          title="Off Field"
          text="Our off field club gear will keep people staring. We want every team to be excited for their team on and off the field. Keep looking good whilst staying comfortable."
          buttons={[
            { text: 'AFL', to: '/sports/afl#off-field' },
            // { text: 'Athletics', to: '/sports/athletics#off-field' },
            { text: 'Touch', to: '/sports/touch-football#off-field' },
            { text: 'Basketball', to: '/sports/basketball#off-field' },
            { text: 'Cricket', to: '/sports/cricket#off-field' },
            { text: 'Hockey', to: '/sports/hockey#off-field' },
            { text: 'Netball', to: '/sports/netball#off-field' },
            { text: 'Rugby', to: '/sports/rugby#off-field' },
            { text: 'Soccer', to: '/sports/soccer#off-field' },
            { text: 'Schools', to: '/sports/schools#off-field' },
            { text: 'Corporate', to: '/sports/corporate#off-field' },
            { text: 'Hi-Vis', to: '/sports/hi-vis#off-field' }
          ]}
        />
        <ContentCard
          image={data.training.childImageSharp}
          title="Training"
          text="Wearing your clubs colours should instill a sense of pride, and should always look professional, even if you’re on a social team or just training with your team."
          buttons={[
            { text: 'AFL', to: '/sports/afl#training' },
            { text: 'Touch', to: '/sports/touch-football#training' },
            { text: 'Basketball', to: '/sports/basketball#training' },
            { text: 'Cricket', to: '/sports/cricket#training' },
            { text: 'Hockey', to: '/sports/hockey#training' },
            { text: 'Netball', to: '/sports/netball#training' },
            { text: 'Rugby', to: '/sports/rugby#training' },
            { text: 'Soccer', to: '/sports/soccer#training' },
            { text: 'Schools', to: '/sports/schools#training' },
            { text: 'Corporate', to: '/sports/corporate#training' },
            { text: 'Hi-Vis', to: '/sports/hi-vis#training' }
          ]}
        />
        <ContentCard
          image={data.accessories.childImageSharp}
          title="Accessories"
          text="It's not all about clothing, check out some of the accessories we have on offer to take your club brand to the next level. As always, custom designed and tailored to your needs."
          buttons={[
            { text: 'AFL', to: '/sports/afl#accessories' },
            { text: 'Touch', to: '/sports/touch-football#accessories' },
            { text: 'Basketball', to: '/sports/basketball#accessories' },
            { text: 'Cricket', to: '/sports/cricket#accessories' },
            { text: 'Hockey', to: '/sports/hockey#accessories' },
            { text: 'Netball', to: '/sports/netball#accessories' },
            { text: 'Rugby', to: '/sports/rugby#accessories' },
            { text: 'Soccer', to: '/sports/soccer#accessories' },
            { text: 'Schools', to: '/sports/schools#accessories' },
            { text: 'Corporate', to: '/sports/corporate#accessories' },
            { text: 'Hi-Vis', to: '/sports/hi-vis#accessories' }
          ]}
        />
      </AlternatingContent>

      <Flex justifyContent="center" mb={5}>
        <Button>3-D Kit Maker Coming Soon!</Button>
      </Flex>

      <Hero bgImage={data.sportsBreak.childImageSharp} height="750px">
        <h1>Do you have questions?</h1>
        <p>
          Call 0418 981 023 or please complete the enquiry form to contact us
          today and find out how we can help.
        </p>
        <Flex>
          <Button color={'#fff'} small={true} outline={true}>
            Contact Us
          </Button>
        </Flex>
      </Hero>

      <Testimonials testimonials={allTestimonials} />

      <VideoContainer>
        <h2>Australia's leading sportswear supplier</h2>

        <section>
          {data.shirts.edges.map(
            image =>
              image.node.childImageSharp && (
                <figure>
                  <Img fluid={image.node.childImageSharp.fluid} />
                </figure>
              )
          )}
        </section>

        <article>
          <p>
            <i>Welcome to Australia's most flexible sportswear manufacturer.</i>
          </p>

          <p>
            We know what it's like to sweat it out on the playing field and the
            importance of staying comfortable. Our team have been active sports
            players at the national and local club levels for years.{' '}
          </p>

          <p>
            The team at GFit have decades of experience within the sporting
            industry. Our emphasis is on making the process of creating great
            designs as simple and enjoyable for you as possible.
          </p>

          <p>
            We work hard to provide sports uniforms, school sports uniforms,
            corporate uniforms and merchandise to your specific needs. Offering
            the highest quality custom sports teamwear on the market today.
          </p>

          <p>
            We service every major sport in Australia. Netball, Cricket, AFL,
            Soccer, Hockey, and so many more. We also offer high visibility
            corporate workwear and school sporting uniforms.
          </p>

          <p>
            Through our in house designers we can bring any design aspiration to
            reality within 24 hours. We pride ourselves on our fast and high
            quality design iterations, always ensuring that you are happy with
            the direction and end result.
          </p>

          <p>
            <i>
              We are in love with all sport. It's a part of us. We feel that
              sport is not something you do, it’s something you are. We
              understand the importance and delicacy of our role in your club's
              life.
            </i>
          </p>

          <p>
            We continue to work with some of the biggest athletes, teams, and
            schools in Australia to ensure our product remains unsurpassed. We
            relentlessly improve and iterate from the feedback we get from these
            atheletes. And your team reaps the rewards, with access to the exact
            same apparel as these major leagues.
          </p>
          <p>
            If you're interested in getting a custom design of your own for you
            or your club, contact us today and we'll give you a call to discuss
            your needs. You can also browse our sports to see some of our
            previous designs.
          </p>
        </article>
      </VideoContainer>

      <AboutContact data={data} />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    womanWaterfall: file(relativePath: { eq: "homepage-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shirts: allFile(filter: { relativeDirectory: { eq: "home-shirts" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 700, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    logos: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    offField: file(relativePath: { eq: "off-field.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    onField: file(relativePath: { eq: "on-field.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "training.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessories: file(relativePath: { eq: "accessories.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportsBreak: file(relativePath: { eq: "sports-break.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
