import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea } from './Input'
import { yPad, xPad } from '../utils/theme'
import Form from './Form'

interface IContainerProps {
  noBorder: boolean
}

export const Container = styled<IContainerProps, any>('div')`
  ${yPad};
  ${xPad};

  display: flex;
  justify-content: center;
  width: 80%;
  margin: 30px auto;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Information = styled.div`
  flex-basis: 25%;
  margin-right: 80px;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  h2 {
    margin-bottom: 2rem;
    font-weight: 400;
    color: ${props => props.theme.colors.primary};
  }
  span {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 3px;
  }
  p {
    font-size: 1.3rem;
    margin-top: 1.3rem;
    line-height: 1.4;
  }
`

interface Props {
  children?: React.ReactChild
  title?: string
  showMessage?: boolean
  id?: string
  type?: 'audit'
}

export default class extends React.Component<Props> {
  render() {
    return (
      <Container {...this.props}>
        <Information>
          <h2>Phone</h2>
          <span>0418 981 023</span>
          <p />
          <strong style={{ fontSize: 16, marginBottom: 0 }}>
            <a
              href="mailto:contact@gfitwear.com
"
            >
              contact@gfitwear.com
            </a>
          </strong>
        </Information>
        <Form {...this.props} />
      </Container>
    )
  }
}
